import React from "react"
import theme from "theme"
import { Theme, Text, Box, Section, Image } from "@quarkly/widgets"
import { Helmet } from "react-helmet"
import { GlobalQuarklyPageStyles } from "global-page-styles"
import { RawHtml, Override } from "@quarkly/components"
import * as Components from "components"
export default () => {
  return (
    <Theme theme={theme}>
      <GlobalQuarklyPageStyles pageUrl={"faq"} />
      <Helmet>
        <title>FAQ | Івент агенція RUSH</title>
        <meta
          name={"description"}
          content={"Відповіді на ваші запитання, розвіяні сумніви!"}
        />
        <meta property={"og:title"} content={"FAQ | Івент агенція RUSH"} />
        <meta
          property={"og:description"}
          content={"Відповіді на ваші запитання, розвіяні сумніви!"}
        />
        <meta
          property={"og:image"}
          content={"https://top.movixentop.com/img/1.jpg"}
        />
        <link
          rel={"shortcut icon"}
          href={"https://top.movixentop.com/img/birthday.png"}
          type={"image/x-icon"}
        />
        <link
          rel={"apple-touch-icon"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"76x76"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <link
          rel={"apple-touch-icon"}
          sizes={"152x152"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <link
          rel={"apple-touch-startup-image"}
          href={"https://top.movixentop.com/img/birthday.png"}
        />
        <meta
          name={"msapplication-TileImage"}
          content={"https://top.movixentop.com/img/birthday.png"}
        />
      </Helmet>
      <Components.Header />
      <Section
        padding="100px 0 100px 0"
        background="#ffffff"
        quarkly-title="FAQ-11"
      >
        <Text
          margin="0px 0px 70px 0px"
          font="normal 900 52px/1.2 --fontFamily-sans"
          color="#0e1940"
          text-align="center"
          lg-margin="0px 0px 50px 0px"
          sm-font="normal 900 36px/1.2 --fontFamily-sans"
        >
          Відповіді на ваші запитання
        </Text>
        <Box
          min-width="100px"
          min-height="100px"
          display="grid"
          grid-template-columns="repeat(3, 1fr)"
          grid-gap="16px 24px"
          lg-grid-template-columns="1fr"
        >
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Які заходи ви організовуєте?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Ми спеціалізуємося на широкому спектрі заходів, включаючи
                конференції, концерти, весілля та святкування важливих днів
                народження. Кожна подія розробляється з урахуванням унікальних
                потреб і побажань наших клієнтів.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Як мені почати планувати захід з вами?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Просто зв'яжіться з нами по телефону або електронною поштою, і
                ми призначимо консультацію, щоб детально обговорити вашу подію.
                Саме тут починає формуватися ваше бачення!
              </Text>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Чи можете ви проводити заходи в різних локаціях?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Так, наша команда здатна організовувати заходи в різних умовах,
                як в приміщенні, так і на відкритому повітрі, в різних
                місцевостях.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Що входить у ваші послуги з планування заходів?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Наші послуги охоплюють все: від пошуку місця проведення,
                кейтерингу, розваг, декору до повної логістичної підтримки. Ми
                керуємо всіма аспектами, щоб забезпечити бездоганну організацію
                заходу.
              </Text>
            </Box>
          </Box>
          <Box min-width="100px" min-height="100px">
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              margin="0px 0px 30px 0px"
              lg-margin="0px 0px 16px 0px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                За скільки часу я повинен бронювати свій захід?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Ми рекомендуємо бронювати якомога раніше, бажано за 4-6 місяців,
                щоб забезпечити найкращий вибір дат і послуг.
              </Text>
            </Box>
            <Box
              min-width="100px"
              min-height="100px"
              border-width="1px"
              border-style="solid"
              border-color="--color-lightD2"
              border-radius="15px"
              padding="25px 25px 25px 25px"
              sm-padding="18px 18px 18px 18px"
            >
              <Text
                margin="0px 0px 15px 0px"
                font="normal 600 25px/1.2 --fontFamily-sans"
                color="#0e1940"
              >
                Чи надаєте ви послуги для невеликих груп?
              </Text>
              <Text
                margin="0px 0px 0px 0px"
                font="normal 300 18px/1.5 --fontFamily-sansTrebuchet"
                color="#555a5f"
              >
                Безумовно! Ми пропонуємо індивідуальні послуги для подій
                будь-якого масштабу, від інтимних зустрічей до масштабних
                публічних заходів.
              </Text>
            </Box>
          </Box>
        </Box>
      </Section>
      <Section
        padding="65px 0 65px 0"
        sm-padding="60px 0 60px 0"
        quarkly-title="Images-11"
      >
        <Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
        <Box
          display="flex"
          width="50%"
          lg-width="100%"
          lg-align-items="center"
          sm-padding="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          flex-direction="column"
          align-items="flex-start"
          lg-margin="0px 0px 0px 0px"
          sm-margin="0px 0px 0px 0px"
        >
          <Text
            sm-font="--headline3"
            margin="0px 0px 36px 0px"
            color="--darkL2"
            font="--headline2"
            lg-text-align="center"
            md-text-align="left"
          >
            Залишилися питання?
          </Text>
          <Text
            margin="0px 0px 0px 0px"
            color="--darkL2"
            font="--base"
            lg-text-align="center"
            sm-margin="0px 0px 30px 0px"
            md-text-align="left"
          >
            Якщо ви не знайшли відповідь на своє запитання, будь ласка,
            зв'яжіться з нами напряму. Ми тут, щоб допомогти вам і гарантувати,
            що процес планування вашого заходу пройде якнайкраще.{" "}
          </Text>
        </Box>
        <Box
          width="50%"
          lg-width="100%"
          margin="0px 0px 0px 0px"
          padding="16px 16px 16px 16px"
          sm-padding="0px 0px 0px 0px"
          sm-margin="0px 0px 30px 0px"
          flex-direction="column"
          display="flex"
        >
          <Box
            width="100%"
            height="auto"
            padding="0px 0px 70% 0px"
            transition="transform 0.2s ease-in-out 0s"
            hover-transform="translateY(-10px)"
            overflow-x="hidden"
            overflow-y="hidden"
            position="relative"
            transform="translateY(0px)"
          >
            <Image
              position="absolute"
              width="100%"
              top="auto"
              left={0}
              src="https://top.movixentop.com/img/3.jpg"
              object-fit="cover"
              display="block"
              right={0}
              bottom="0px"
              min-height="100%"
            />
          </Box>
        </Box>
      </Section>
      <Components.Footer />
      <RawHtml>
        <style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
          {
            ":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"
          }
        </style>
      </RawHtml>
    </Theme>
  )
}
